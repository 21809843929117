import NavigationList from "@components/navigation-list";
import SiteLogo from "@components/site-logo";
import { IconClose, IconMenu, Link } from "@reusable";
import React, { useState } from "react";
import "./styles.scss";

/*
  <SiteHeader />

  The <SiteHeader /> is responsible for the repeating
  website header that appears on every page. It contains
  the top level links to resources, then the primary header,
  which has the site logo and navigation to primary pages.

  Navigation uses the <details><summary> elements to
  allow navigation on small screens to work without
  JavaScript. Progressive enhancement style!

  Navigation TODOs/enhancements:
    - [ ] Close the navigation on ESC, on click outside,
    and when focus leaves (tab past the last item).
    - [ ] Add animation for open/close icon. eg: https://jonsuh.com/hamburgers/
*/
export default function SiteHeader() {
  const [navOpen, setNavOpen] = useState(false);

  const onNavToggle = (event) => {
    event.preventDefault();
    setNavOpen(!navOpen);
  };

  return (
    <div className="site-header-container">
      <div className="site-header-top-links">
        <a
          href="/pdf/rolvedon-prescribing-information.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Prescribing Information
        </a>
        <Link
          affiliated={true}
          href="https://www.rolvedon.com/#tray"
          target="_blank"
          rel="noopener noreferrer"
        >
          Important Safety Information
        </Link>
        <Link
          affiliated={true}
          href="https://www.rolvedon.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Visit HCP site
        </Link>
      </div>
      <header className="site-header">
        <div className="site-header-inner-container">
          <nav aria-label="primary" className="site-nav">
            <Link to="/">
              <SiteLogo />
              <span className="visually-hidden">ROLVEDON</span>
            </Link>

            <div className="site-nav--large-screens">
              <NavigationList />
            </div>

            <div className="site-nav--small-screens">
              <details open={navOpen}>
                <summary
                  onClick={onNavToggle}
                  style={{
                    lineHeight: 0,
                  }}
                >
                  {navOpen ? (
                    <IconClose size="32px" />
                  ) : (
                    <IconMenu size="32px" />
                  )}
                  <span className="visually-hidden">Menu</span>
                </summary>
                <div className="site-nav-dropdown">
                  <NavigationList home={true} hcp={true} />
                </div>
              </details>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}
