import OnEntryModal from "@components/on-entry-modal";
import SiteFooter from "@components/site-footer";
import SiteHeader from "@components/site-header";
import PageHeader from "@reusable/page-header";
import React from "react";
import Helmet from "react-helmet";
import ScrollToTop from "react-scroll-to-top";
import favicon from "../images/favicon.ico";
import "../styles/main.scss";

const BaseLayout = ({ children, ...props }) => {
  const frontmatter = props.pageContext ? props.pageContext.frontmatter : {};
  const { title, description } = frontmatter;

  return (
    <React.Fragment>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />

        {/* Browser Bar on Mobile */}
        <meta name="theme-color" content="#87189D" />
        {/* END Browser Bar on Mobile */}

        {/* Titles */}
        <title>Reimbursement | ROLVEDON® (eflapegrastim-xnst)</title>
        <link rel="canonical" href="https://www.access4me.com/reimbursement" />
        <meta
          property="og:url"
          content="https://www.access4me.com/reimbursement"
        />
        <meta
          name="twitter:url"
          content="https://www.access4me.com/reimbursement"
        />
        <meta property="og:title" content="ACCESS4Me Provider Portal" />
        <meta property="og:site_name" content="ACCESS4Me Provider Portal" />
        <meta name="twitter:title" content="ACCESS4Me Provider Portal" />
        <meta itemprop="name" content="ACCESS4Me Provider Portal" />
        {/* END Titles */}

        {/* Descriptions */}
        <meta
          name="description"
          content="ACCESS4Me reimbursement information for ROLVEDON and resource information on billing, coding, payer policies, and coverage requirements."
        />
        <meta
          name="keywords"
          content="ACCESS4Me reimbursement, eflapegrastim reimbursement, rolvedon reimbursement, eflapegrastim billing, rolvedon billing, rolvedon coding, eflapegrastim coding, rolvedon coverage, eflapegrastim coverage, rolvedon payer policies, eflapegrastim payer policies,"
        />
        <meta
          property="og:description"
          content="The ACCESS4Me online portal can help reduce enrollment errors that may delay access. Here, you can enroll and manage patients in our support programs. It is available online for instant access to patient case status updates."
        />
        <meta
          name="twitter:description"
          content="The ACCESS4Me online portal can help reduce enrollment errors that may delay access. Here, you can enroll and manage patients in our support programs. It is available online for instant access to patient case status updates."
        />
        <meta
          itemprop="description"
          content="The ACCESS4Me online portal can help reduce enrollment errors that may delay access. Here, you can enroll and manage patients in our support programs. It is available online for instant access to patient case status updates."
        />
        {/* END Descriptions */}

        {/* Canoonical */}

        {/* END Canoonical */}

        {/* Social Image */}
        <meta
          property="og:image"
          content="https://www.access4me.com/images/site-logo.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          name="twitter:image"
          content="https://www.access4me.com/images/site-logo.png"
        />
        <meta
          itemprop="image"
          content="https://www.access4me.com/images/site-logo.png"
        />
        {/* END Social Image */}

        {/* The way things look */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css"
          rel="stylesheet"
          integrity="sha384-T8Gy5hrqNKT+hzMclPo118YTQO6cYprQmhrYwIiQ/3axmI1hQomh7Ud2hPOy8SP1"
          crossorigin="anonymous"
        />
        <link rel="stylesheet" href="/custom.css" />
        {/* END The way things look */}

        {/* Favicon */}
        <link rel="icon" href={favicon} />
        {/* END Favicon */}
      </Helmet>

      <OnEntryModal />
      <SiteHeader />

      {/* Main content */}
      <main>
        {title && <PageHeader {...frontmatter}>{description}</PageHeader>}
        {children}
      </main>
      {/* END Main content */}

      <SiteFooter />
      <ScrollToTop
        smooth
        color="#FFF"
        svgPath="M222.138,91.475l-89.6-89.6c-2.5-2.5-6.551-2.5-9.051,0l-89.6,89.6c-2.5,2.5-2.5,6.551,0,9.051s6.744,2.5,9.244,0L122,21.85  V249.6c0,3.535,2.466,6.4,6,6.4s6-2.865,6-6.4V21.85l78.881,78.676c1.25,1.25,2.992,1.875,4.629,1.875s3.326-0.625,4.576-1.875  C224.586,98.025,224.638,93.975,222.138,91.475z"
      />
    </React.Fragment>
  );
};

export default BaseLayout;
