import { Button, IconArrowRight, Logo, Modal } from "@reusable";
import React, { useEffect, useState } from "react";

export default function OnEntryModal() {
  const [isDismissed, setDismissed] = useState(false);

  /*
    Let's check if the user has dismissed this
    recently with sessionStorage. That we we don't
    need to show it agian and be annoying.

    And we can also set sessionStorage on dismiss.
  */
  useEffect(() => {
    const storageKey = "proceed-to-rolontis";
    const value = sessionStorage.getItem(storageKey);

    // if (value === "true") {
    setDismissed(true);
    //}

    if (isDismissed) {
      sessionStorage.setItem(storageKey, "true");
    }
  }, [isDismissed]);

  if (isDismissed) {
    return null;
  }

  function dismiss() {
    setDismissed(true);
  }

  return (
    <Modal handleDismiss={dismiss}>
      <section
        style={{
          textAlign: "center",
        }}
      >
        <Logo />
        <p
          style={{
            fontSize: "1.25rem",
            color: "#588ab5",
            paddingTop: "14px",
            fontWeight: "600",
          }}
        >
          This website is intended only for healthcare professionals in the
          United States.
        </p>

        <Button onClick={dismiss}>
          <span
            style={{
              marginRight: "1rem",
            }}
          >
            Proceed to site
          </span>
          <IconArrowRight />
        </Button>
      </section>
    </Modal>
  );
}
