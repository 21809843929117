import { Link } from "@reusable";
import React from "react";
import rolvedon from "./ROLVEDON-Logo.png";
import faxPurple from "./fax-purple.svg";
import phonePurple from "./phone-purple.svg";
import spectrum from "./spectrum.svg";
import "./styles.scss";

function sendEmail() {
  window.location = "mailto:rolvedon@sppirx.com";
}

export default function SiteFooter() {
  return (
    <footer className="site-footer animate-on-enter-3">
      <div className="container">
        <div className="site-footer-layout">
          <section className="site-footer-logos">
            <Link affiliated={true} href="https://www.sppirx.com">
              <img src={spectrum} alt="Specturm® logo" className="logo" />
            </Link>{" "}
            <Link affiliated={true} to="https://www.rolvedon.com">
              <img src={rolvedon} alt="ROVELDON" className="logo rolvedon" />
            </Link>
          </section>

          <section>
            <p
              style={{
                fontWeight: "700",
              }}
            >
              Communicate directly with an assigned Spectrum Pharmaceuticals
              Field Reimbursement Manager or Reimbursement Specialist.
            </p>

            <div className="site-footer-links--large">
              <FooterLinks />
            </div>
          </section>

          <section>
            <p
              style={{
                fontWeight: "700",
              }}
            >
              Contact an assigned Reimbursement Specialist:
            </p>

            <p className="center-start">
              <img
                src={phonePurple}
                alt="Phone icon"
                style={{
                  paddingRight: "0.5rem",
                }}
              />
              <a href="tel:1-866-582-2737" className="text-large">
                &nbsp;866-582-2737
                <br />
                (866-58-CARES)
              </a>
            </p>
            <p className="center-start">
              <img
                src={faxPurple}
                alt="Fax icon"
                style={{
                  paddingRight: "0.5rem",
                }}
              />
              <span className="text-large">&nbsp;833-281-7416</span>
            </p>

            <p>
              8:00 <span className="small-caps">AM</span> to 8:00
              <span className="small-caps">PM</span> (ET),
              <br /> Monday - Friday
            </p>

            <div className="site-footer-links--small">
              <FooterLinks />
            </div>
          </section>
        </div>
      </div>
    </footer>
  );
}

function FooterLinks() {
  return (
    <React.Fragment>
      <ul className="site-footer-links">
        <li>
          <a href="mailto:rolvedon@sppirx.com" rel="noopener noreferrer">
            Contact Us
          </a>
        </li>
        <li>
          <Link to="/sitemap">Sitemap</Link>
        </li>
        <li>
          <Link
            affiliated={true}
            href="https://www.assertiotx.com/privacy-policy/"
          >
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link
            affiliated={true}
            href="https://www.assertiotx.com/terms-of-use/"
          >
            Terms of Use
          </Link>
        </li>
        <li>
          <button id="ot-sdk-btn" className="ot-sdk-show-settings">
            Cookie Settings
          </button>
        </li>
      </ul>
      <p>
        ROLVEDON and ACCESS4Me are registered trademarks of Spectrum
        Pharmaceuticals, Inc. © 2024 Spectrum Pharmaceuticals, Inc. A subsidiary
        of Assertio Holdings, Inc. All rights reserved. PP-ROL-00-0695
      </p>
    </React.Fragment>
  );
}
